<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ news.title }}</v-card-title>
                <v-card-text>
                    <NewsTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-menu
                                    ref="dateMenu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFormatted"
                                            label="Дата"
                                            prepend-icon="mdi-calendar"
                                            hide-details
                                            outlined
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        no-title
                                        @input="setDate(date)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="news.title"
                                    outlined
                                    label="Заголовок"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img width="300px" v-if="news.picture" :src="imageSrc(news.picture)" class="mb-4" />
                                <v-file-input
                                    label="Картинка"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setPicture"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="news.site_id"
                                    label="Сайт"
                                    :items="entities"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="news.preview"
                                    outlined
                                    label="Текст для превью"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="news.text"
                                    outlined
                                    label="Текст"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="news.sort"
                                    outlined
                                    label="Сортировка"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="news.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить акцию? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import moment from 'moment';
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import NewsTabLinks from '../../components/news/NewsTabLinks';

    import { imageSrc, translit } from '../../helpers';

    export default {
        name: 'NewsEdit',

        components: {
            NewsTabLinks,
        },

        data() {
            return {
                imageSrc,
                news: {
                    site_id: null,
                    published_at: moment(),
                    picture: '',
                    title: '',
                    preview: '',
                    text: '',
                    sort: 0,
                    enabled: true
                },
                deleteModal: false,
                date: '',
                dateMenu: false
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', ['entities']),
            ...mapState('news', {
                oldNews: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
            dateFormatted() {
                return moment(this.news.published_at).format('DD.MM.YYYY');
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('sites/fetch', {});
            if(id) {
                await this.get(id);
            }
        },

        methods: {
            async setPicture(file) {
                this.news.picture = await store.dispatch('files/upload', { file });
            },
            setDate(date) {
                this.news.published_at = moment(date);
                this.dateMenu = false;
            },
            async get(id) {
                await store.dispatch('news/get', {id});
                this.news = {
                    ...this.oldNews,
                    published_at: moment(this.oldNews.published_at)
                };
            },
            async save() {
                store.commit('news/SET_ENTITY', this.news);
                const { id } = await store.dispatch('news/save');
                this.$router.push(`/news/${ id }/detail`);
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('news/delete', { id });
                    this.$router.push('/news');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/news/${ id }/detail`);
                } else {
                    this.$router.push('/news');
                }
            },
        },
    };
</script>
